import React, { Component } from "react";

import "./index.scss";

export default class HeaderLogo extends Component {
  render() {
    return (
      <div className="header-logo-wrapper">
        <a href="/" className="logo-link"></a>
      </div>
    );
  }
}
